#credit-history-table-container {
  max-height: 388.19px;
  min-height: 388.19px;
  overflow: auto;
}

#credit-history-table-container table thead tr th {
  position: sticky;
  top: 0px;
  z-index: 1;
  vertical-align: middle;
}
