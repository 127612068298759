#admin-overview-tab-content .list-card-body {
  height: 52vh;
}

#admin-overview-tab-content .list-group {
  height: 42vh;
  overflow: auto;
}

.top-cards .table-card table thead tr th {
  position: sticky;
  top: 0px;
  z-index: 1;
  vertical-align: middle;
  /* max-width: 300px; */
}

.top-cards .table-card {
  height: 55vh;
  max-height: 55vh;
  overflow: auto;
  /* position: relative; */
}

.col-transition {
  transition: all 0.4s cubic-bezier(0.2, 0, 1, 1);
  overflow: hidden;
  max-width: 100%;
  opacity: 1;
}

.disabledCol {
  max-width: 0px;
  opacity: 0;
}

#user-history-table .rmsc.multi-select,
#user-credit-table .rmsc.multi-select,
#user-report-log-table .rmsc.multi-select {
  min-width: 255px;
  width: 255px;
  max-width: 100%;
  position: absolute;
  top: 160%;
  left: 0;
  font-size: 0.8rem;
}
