body {
  overflow-y: scroll;
  padding-right: 0px !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(151, 151, 151, 0.4);
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}

.modal-content {
  border: none;
}

.purchase-credit-pill:hover {
  scale: 1.05;
  transition: all 0.2s ease-in-out;
}

.state-and-jurisdiction-limit-input::placeholder {
  text-align: center;
}
