#report-table-container {
  position: relative;
  background: white;
  border-radius: 0.8rem;
}
#report-table-container[data-show="true"] {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#show-table-btn i {
  width: 25%;
}

#show-table-btn {
  min-width: 160px;
  height: 45px;
  font-size: 1rem;
  vertical-align: middle;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

#show-table-btn,
#reports-table-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#ocrTableForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: center;
}

#reports-table-progress {
  width: 65%;
  max-width: 65%;
}

#report-table-container[data-show="false"] #show-table-btn {
  display: block;
}

#report-table-container[data-show="false"] #ocrTableForm {
  display: flex;
}

#report-table-container[data-show="true"] #show-table-btn,
#report-table-container[data-show="true"] #ocrTableForm {
  display: none;
}

#report-table-container[data-show="false"] #report-table {
  filter: blur(10px);
  pointer-events: none;
  user-select: none;
}

#report-table-container[data-show="true"] #report-table {
  filter: none;
  pointer-events: initial;
  user-select: initial;
}

#report-table-container .table-responsive {
  max-height: 61vh;
  min-height: 61vh;
  overflow: auto;
  border-radius: 0.8rem;
}

#report-table-container[data-show="false"] .table-responsive {
  overflow: hidden;
}

#report-table thead tr th {
  position: sticky;
  top: 0px;
  background-color: #dbdbdb;
  z-index: 1;
  vertical-align: middle;
  /* padding: 6px 8px; */
  /* font-size: 0.82rem; */
}

#report-table tbody tr td {
  vertical-align: middle;
  padding: 6px 8px;
  /* font-size: 0.8rem; */
}

#report-table tbody tr td ul {
  margin: auto;
}

#report-table thead tr th::after {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #dbdbdb;
}

#report-table thead tr th > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.filters-and-sort-icons {
  display: flex;
  gap: 12px;
  font-size: 1rem;
  align-items: center;
}
.filters-and-sort-icons i {
  cursor: pointer;
}

div[id^="react-select-"] {
  z-index: 2;
}

#report-table ul {
  list-style: none;
  padding: 0;
}

#report-table .badge {
  font-size: 0.8rem;
}

.cart-btn {
  min-width: 100px;
}

#report-table .badge-soft-primary {
  color: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.1);
}

.filter-container {
  position: relative;
  display: grid;
  place-items: center;
}

#report-table .rmsc.multi-select {
  min-width: 255px;
  width: 255px;
  max-width: 100%;
  position: absolute;
  top: 200%;
  left: 0;
  font-size: 0.8rem;
}

.rmsc.multi-select .dropdown-container {
  z-index: 9999;
}

.rmsc.multi-select .dropdown-container[aria-expanded="false"] {
  display: none;
}

.rmsc.multi-select .options .select-item {
  margin-bottom: 0;
}

#report-table .rmsc.multi-select.left {
  right: 0;
  left: initial;
}

.show-loader {
  position: relative;
}

.show-loader div[id^="react-select"]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  background-image: url("https://icon-library.com/images/loading-icon-transparent-background/loading-icon-transparent-background-12.jpg");
  width: 25px;
  aspect-ratio: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
}

#report-table th {
  min-width: fit-content;
}

#report-type-header,
#crash-date-header,
.cars-info,
#price-header,
#previously-downloaded-header,
#add-to-cart-header,
#no-of-cars-header {
  width: 0px;
}

#agency-name-header {
  width: 100%;
}
