//
// badge.scss
//

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
}

// Outline badge
@mixin badge-variant-outline($bg) {
  color: $bg;
  border: 1px solid $bg;
  background-color: transparent;
}

@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-variant-outline($value);
  }
}

// badge outline

.badge-outline {
  background-color: $card-bg;
  border: 1px solid;
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }

  .badge-outline {
    &.badge-#{$color} {
      @include badge-variant-outline($value);
    }
  }

  .badge-label {
    &.bg-#{$color} {
      &:before {
        border-right-color: $value;
      }
    }
  }
}

// badge-border

.badge-border {
  border-left: 2px solid;
}

// badge-label

.badge-label {
  margin-left: 8px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-right-color: $primary;
    left: -14px;
    top: 0;
  }
}

[data-layout-mode="dark"] {
  .badge {
    &.bg-dark {
      background-color: var(--#{$variable-prefix}light) !important;
    }

    &.bg-light {
      background-color: var(--#{$variable-prefix}dark) !important;
      color: var(--#{$variable-prefix}light) !important;
    }
  }
}
