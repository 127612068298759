.downloaded-tables-container {
  min-height: 50vh;
}

.downloaded-tables thead tr th > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.downloaded-tables .rmsc.multi-select {
  min-width: 255px;
  width: 255px;
  max-width: 100%;
  position: absolute;
  top: 150%;
  left: 0;
  font-size: 0.8rem;
}

.downloaded-tables .rmsc.multi-select.left {
  left: initial;
  right: 0;
}
